@use "src/common/scss/core/variables" as *;

.card {
  background: $white;
  padding: 16px 24px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 12px;
  width: auto;
  border-radius: 4px;

  .icon {
    text-align: center;
    font-size: 48px;
    color: $primary;
  }

  a {
    font-size: 1.2em;
  }
}


@media screen and (max-width: $mobile-tablet-max-width) {
  .card {
    margin: 12px auto;
  }
}
