@use "src/common/scss/core/variables" as *;

.jumbotron.parallax {
  height: 40vh;
  width: 100%;
  background: $white;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  background: aqua;
  background: url("/assets/img/parallax.jpg") no-repeat fixed center;
  color: white;

  .container {
    z-index: 1;
    position: relative;
    height: 100%;
  }

  h1 {
    margin: 0 auto;
    text-align: center;
    font-size: $font-size-base *3;
  }

  .slug {
    text-align: center;
    font-size: $font-size-h2;
  }
}

