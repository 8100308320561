@use "src/common/scss/core/variables" as *;

.cookie-consent-bar {

  background: $cookie-banner-background;
  color: $cookie-banner-color;
  position: fixed;
  top: auto;
  bottom: 0;
  left: 0;
  //width: 100%;
  box-shadow: 0 -1px 10px 0px rgba($cookie-banner-border, 0.3);;
  border: $gray-10;
  z-index: 9997;
  padding: 15px;

  &.hidden {
    display: none;
  }

  .cookie-consent-title {
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 4px;
  }

  .content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cookie-consent-bar-text {
      text-align: left;
      font-size: 0.85em;
      margin-top: 0;
      margin-bottom: 0;
    }

    .button-wrapper {
      margin-left: 20px;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      .button-cookie {
        font-size: 0.8em;
        padding: 0.5rem 1rem;
        display: inline-block;
        white-space: nowrap;
        text-align: center;
        vertical-align: middle;
        transition: all 0.15s ease-in-out;
        min-height: auto;
        border: 1px solid transparent;
        margin-right: 15px;
        user-select: none;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &.button-settings {
          background: transparent;
          color: $primary;
          margin-left: auto;
          position: relative;
          padding-right: 16px;

          &:before {
            border-style: solid;
            border-width: 1px 1px 0 0;
            content: '';
            display: inline-block;
            height: 4px;
            right: 8px;
            position: absolute;
            border-color: $primary;
            top: 9px;
            transform: rotate(135deg);
            vertical-align: middle;
            width: 4px;
          }
        }

        &.button-reject {
          color: $primary;
          background: $white;
          font-weight: bold;
          border: 1px solid $primary;

          &:hover {
            opacity: 0.8;
          }
        }

        &.button-accept {
          color: $white;
          background: $primary;
          font-weight: bold;

          &:hover {
            opacity: 0.8;
          }
        }

      }

    }
  }

}

@media (min-width: 1415px) {
  .cookie-consent-bar {
    padding: 15px 160px;
  }
}

@media (min-width: 1150px) {
  .cookie-consent-bar {
    padding: 15px 130px;
  }
}

@media (min-width: 991px) {
  .cookie-consent-bar {
    padding: 15px 50px;
  }
}

@media (max-width: 991px) {
  .cookie-consent-bar {
    .content-wrapper {
      display: block;
      text-align: center;

      .button-wrapper {
        display: block;
        text-align: center;
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }
}

@media (max-width: 500px) {
  .cookie-consent-bar {
    .content-wrapper {
      .button-wrapper {
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;

        .button-cookie {
          margin-right: 0;
          margin-top: 5px;
        }
      }
    }
  }
}

