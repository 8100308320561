@use "src/common/scss/core/variables" as *;

.quote-primary {
  background: $primary;
  color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;

  h1 {
    margin-top: 0;
    text-align: center;
    font-size: 3em;
    margin-bottom: 0.2em;
    text-shadow: 4px 4px 4px #262626;
  }

  hr {
    width: 70px;
    height: 4px;
    border: 0;
    background: $white;
    box-shadow: 4px 4px 4px black;
  }

  .quote-text {
    max-width: 600px;
    width: 100%;
    height: auto;
    text-align: center;
    text-shadow: 1px 1px 1px #262626;
  }

}

@media screen and (max-width: $mobile-tablet-max-width) {
  .quote-primary {
    padding: 32px 16px;
    h1 {
      font-size: 2em;
      margin-bottom: 0.2em;
    }
  }
}

@media screen and (min-width: $tablet-min-width) and(max-width: $mobile-tablet-max-width) {
  .quote-primary {
    h1 {
      font-size: 3em;
    }
  }
}
