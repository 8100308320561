@use "src/common/scss/core/variables" as *;

.full-screen {
  width: 100vw;
  height: 95vh;

  .overlay {
    position: absolute;
    width: 100vw;
    height: 95vh;
    top: 0;
    left: 0;
    background: url("/assets/img/cleaning-monkeys-hendrik-jan.jpg") no-repeat;
    background-position: center 0;
    background-size: cover;
    -webkit-background-size: cover;
    z-index: -1;
    display: none;
  }

  .video-overlay {
    position: absolute;
    width: 100vw;
    height: 95vh;
    top: 0;
    left: 0;
    object-fit: cover;
    //background: url("/assets/img/laswerken-tuur-slijpen.jpg") no-repeat;
    //background-position: center 0;
    //background-size: cover;
    //-webkit-background-size: cover;
    z-index: -1;
  }

  .background {
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .site-header-logo {
      width: 100%;
      text-align: center;

      img {
        width: auto;
        max-height: 260px;
        padding: 0 32px;
        display: inline-block;
        box-sizing: border-box;
      }
    }

    .quote-big {
      width: 100%;
      font-size: 3em;
      flex-direction: row;
      color: white;
      text-align: center;
      margin-top: 20px;
      text-shadow: 4px 4px 4px $black;
    }
  }
}

@media screen and (max-width: $mobile-tablet-max-width) {
  .full-screen {
    height: 100vh;
  }
}

@media screen and (max-width: $mobile-max-width) {
  .full-screen .background .quote-big {
    font-size: 2em;
  }

}

@media screen and (max-width: 500px) {
  .full-screen {

    .overlay {
      display: block;
    }

    .video-overlay {
      display: none;
    }
  }
}
