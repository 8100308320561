$font-family-header: 'Open Sans', serif;
$fontBase: 'Open Sans', sans-serif;
$font-size-base: 16px;
$font-size-h1: $font-size-base * 1.6;
$font-size-h2: $font-size-base * 1.4;
$font-size-h3: $font-size-base * 1.2;
$font-size-h4: $font-size-base;
$font-size-h5: $font-size-base;
$font-size-p: $font-size-base;
$font-size-menu-item: $font-size-base * 1.2;
$font-size-footer-information: $font-size-base * 0.9;

$white: #ffffff;
$black: #262626;
$gray-10: #f6f6f6;
$gray-20: #e8e8e8;
$red: #ff2731;
$primary: #A8D054;
$secondary: #93bd3e;
$link-color: $secondary;
$brown: #30100F;

$header-background-color: transparent;
$footer-background-color: $brown;

$header-text-color: $black;
$footer-text-color: $white;

$primary-gradient: linear-gradient(135deg, $primary 0%, $secondary 100%);
$primary-gradient-reversed: linear-gradient(135deg, $secondary 0%, $primary 100%);


$tColor: color .2s ease;

$mobile: 'screen and (max-width: 767px)';
$tablet: 'screen and (min-width: 768px) and (max-width: 1023px)';
$mobile-tablet: 'screen and (max-width: 1023px)';
$tablet-desktop: 'screen and (min-width: 768px)';
$desktop: 'screen and (min-width: 1024px)';

$mobile-max-width: 767px;
$tablet-min-width: 768px;
$mobile-tablet-max-width: 1023px;
$desktop-min-width: 1024px;

$colCount: 12;
$colGapDesktop: 4rem;
$colGapTablet: 2rem;

$containerWidth: 116rem;
$containerWidthTablet: 76.8rem;

$sectionOffsetVertical: 8rem;
$sectionOffsetHorizontal: 3rem;
$sectionOffsetHorizontalTablet: 4rem;
$sectionOffsetVerticalTablet: 6rem;
$sectionOffsetVerticalMobile: 4rem;
$sectionOffsetHorizontalMobile: 2rem;

// Section Variables
$photo-gallery-preview-background: $black;
$photo-gallery-preview-color: $white;

// Cookie Banner
$cookie-banner-background: $white;
$cookie-banner-color: $black;
$cookie-banner-border: $gray-10;

// Timeline
$timeline-border-color: $gray-20;
