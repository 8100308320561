@use "src/common/scss/core/variables" as *;

.full-width {
  padding: 32px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;

  .col {
    flex: 1 1 0;
    margin: 16px;

    &.text-col {
      width: 100%;
      height: auto;

      h1 {
        font-size: 3em;
        color: $primary;
        margin-bottom: 6px;
      }

      hr {
        width: 120px;
        height: 4px;
        border: 0;
        background: $primary;
        margin: 0 0 36px;
      }

      .cta-wrapper {
        max-width: 200px;
      }
    }

    &.image-col {
      img {
        width: auto;
        max-width: 100%;
        height: auto;
        border: 6px solid $white;
        box-sizing: border-box;

      }
    }
  }

}

@media screen and (min-width: $tablet-min-width) and(max-width: $mobile-tablet-max-width) {
  .full-width {
    .col {
      &.text-col {
        h1 {
          font-size: 3em;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-tablet-max-width) {
  .full-width {
    flex-direction: column;
    margin-top: 0;
    padding: 0 32px;

    .col {
      margin: 0;

      &.text-col {
        max-width: none;
        margin: 0 0 16px 0;

        h1 {
          font-size: 2em;
        }
      }

    }

    .decoratie {
      display: none;
    }
  }
}


