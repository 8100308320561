@use "core/variables" as *;

.sl-overlay {
  background: $black;
  opacity: 0.8;
}

.sl-wrapper {
  .sl-close,
  .sl-navigation button {
    color: $white;
  }
}
