@use "src/common/scss/core/variables" as *;

.jumbotron.gaussian {
  height: 40vh;
  width: 100%;
  background: $white;
  min-height: 500px;
  display: flex;
  flex-direction: column;

  .gaussian-container {
    position: relative;
    height: 100%;
    width: 100%;

    .gaussian-blur {
      background: url("/assets/img/background.png") no-repeat center;
      filter: blur(10px);
      opacity: 0.5;
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 0;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .container {
      z-index: 1;
      position: relative;
    }

    h1 {
      margin: 0 auto;
      text-align: center;
      font-size: $font-size-base *3;
    }

    .slug {
      text-align: center;
      font-size: $font-size-h2;
    }
  }


}
