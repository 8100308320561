$widthTablet: 768px;
$widthDesktop: 1024px;

/* 1. Overal styling mixins */
@mixin section-shadow {
  box-shadow: 0 20px 30px 0 rgb(0 0 0 / 10%);
}

@mixin shadow {
  box-shadow: 0 0.125rem 0.375rem rgb(0 0 0 / 5%), 0 0.5rem 1.2rem rgb(0 0 0 / 10%) !important;
}

/* 1. End of overal styling mixins */


/* 2. Responsive helper mixins */
@mixin mobile {
  @media (max-width: #{$widthTablet - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$widthTablet}) and (max-width: #{$widthDesktop - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$widthDesktop}) {
    @content;
  }
}


@mixin mq($from, $to: false) {
  @if $to {
    @media (min-width: $from + 0px) and (max-width: $to + 0px) {
      @content;
    }
  } @else {
    @media (max-width: $from + 0px) {
      @content;
    }
  }
}

@mixin mqh($from, $to: false) {
  @if $to {
    @media (min-height: $from + 0px) and (max-height: $to + 0px) {
      @content;
    }
  } @else {
    @media (max-height: $from + 0px) {
      @content;
    }
  }
}

/* 2. End of Responsive helper mixins */
