@use "src/common/scss/core/variables" as *;

.timeline-wrapper {
  .row {
    justify-content: space-between;
    text-align: center;

    .timeline-item {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      flex: 1;

      .timeline-number-wrapper {
        position: relative;
        width: 100%;
        z-index: 1;

        .timeline-number {
          background: $primary;
          color: $white;
          padding: 8px;
          font-size: 1.2em;
          font-weight: bold;
          margin: 0 auto;
          border-radius: 50%;
          height: 50px;
          width: 50px;
          line-height: 50px;
          display: inline-block;
        }

        .border-right:after {
          content: "";
          position: absolute;
          top: 50%;
          display: block;
          width: calc(50% + 30px);
          border-top-width: 1px;
          border-top-style: solid;
          margin-top: -1px;
          right: -30px;
          border-color: $timeline-border-color !important;
          z-index: -1;
        }

        .border-left:before {
          content: "";
          position: absolute;
          top: 50%;
          display: block;
          width: calc(50% + 30px);
          border-top-width: 1px;
          border-top-style: solid;
          margin-top: -1px;
          left: -30px;
          border-color: $timeline-border-color !important;
          z-index: -1;
        }
      }

      p {
        padding: 0 8px;
      }
    }
  }
}

@media screen and (max-width: $mobile-tablet-max-width) {
  .timeline-wrapper {
    .row {
      flex-direction: column;
      .timeline-item {
        margin-bottom: 32px;

        .timeline-number-wrapper {
          .border-right:after {
            content: none;
          }
          .border-left:before {
            content: none;
          }
        }
      }
    }
  }
}
