@use "src/common/scss/core/variables" as *;

.cta-light {
  background: $primary-gradient;
  color: $white;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: $containerWidth;
    padding: 48px 32px;
    width: auto;
    margin: 0 auto;
  }

  .text {
    font-size: 2.5em;
    font-family: $font-family-header;
    font-weight: bold;
    width: auto;
    height: auto;
    text-align: left;
    margin: 0;
    flex: 1;
  }

  .button {
    color: $primary;
    transition: 0.5s;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 17px rgb(0 0 0 / 40%);
    }

    .white {
      background: $white;
    }
  }

}

@media screen and (max-width: $mobile-tablet-max-width) {
  .cta-light {
    flex-direction: column;

    > div {
      flex-direction: column;
    }

    .text {
      max-width: none;
      font-size: 2em;
      margin-bottom: 24px;
      text-align: center;
    }
  }
}

@media screen and (min-width: $tablet-min-width) and(max-width: $mobile-tablet-max-width) {
  .cta-light {
    .text {
      font-size: 2.2em;
    }
  }
}
