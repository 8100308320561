@use "src/common/scss/core/variables" as *;

.footer {
  padding: 4rem 0;
  background: $footer-background-color;
  color: $footer-text-color;
  z-index: 5;

  a {
    color: $white;
  }

  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 650px;
    margin: 0 auto;

    .footer- {

      &company-information {
        max-width: 700px;
        font-size: $font-size-footer-information;
        display: flex;
        flex-direction: column;
        align-items: center;

        .site-logo {
          max-width: 300px;
          min-width: 150px;
          width: 100%;
        }

        .footer-company-description {
          margin: 16px 0;
          text-align: center;
        }
      }

      &widget {
        max-width: 300px;
      }

      &contact-information {
        max-width: 500px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        flex-direction: column;

        .footer-contact-information {

          &-phone, &-mail {
            display: flex;
            align-items: center;
            margin: 16px 0;

            .footer-contact-information-icon {
              width: 20px;
              margin-right: 16px;
              text-align: center;
            }
          }
        }


      }

      &copyright {
        margin: 16px 0;
        display: flex;
        width: 100%;
        font-size: 0.9em;
        flex-direction: column;
        text-align: center;

        .copyright {
          margin-bottom: 12px;
        }

      }

      &legal {
        .legal-links {
          text-align: center;
          font-size: 0.9em;

          .underline:first-child {
            margin-bottom: 12px;
            display: inline-block;
          }

          .seperator {
            display: inline;
            visibility: hidden;

            &::after {
              content: "";
              clear: both;
              display: table;
            }
          }

        }
      }
    }
  }
}

.footer-title {
  margin-bottom: 8px;
}

@media screen and (min-width: $desktop-min-width) {
  .footer {

    .footer-container {


      .footer- {

        &company-information {
          //width: 300px;

          .site-logo {
            max-width: 300px;
            min-width: 150px;
            width: 100%;
          }
        }

        &widget {
          //width: 300px;
        }

        &contact-information {
          //width: 300px;
          flex-direction: row;

        }


        &copyright {
          flex-direction: row;
          justify-content: space-between;

          .copyright {
            margin-left: 12px;
          }
        }

        &legal {
          .legal-links {
            text-align: center;

            .underline:first-child {
              margin-bottom: 0;
              display: inline;
            }

            .seperator {
              display: inline;
              visibility: visible;

              &::after {
                content: "";
                clear: none;
                display: inline;
              }
            }

          }
        }
      }
    }


  }
}

