@use "src/common/scss/core/variables" as *;
@use "src/common/scss/core/mixins" as *;

.footer-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  list-style-type: none;
  padding: 0;

  .footer-menu-item {
    align-self: stretch;
    margin: 8px 0;

    .footer-link-url {
      padding: 0;
      text-decoration: none;

      span {
        position: relative;

        &:after {
          content: " ";
          position: absolute;
          background: $primary;
          top: 100%;
          margin-top: 4px;
          left: 0%;
          width: 100%;
          height: 3px;
          transform: scaleX(0);
          transition: -webkit-transform 0.2s;
          transition: transform 0.2s;
          transform-origin: right center;
          box-sizing: border-box;
          display: block;
        }
      }
    }

    &:hover {
      .menu-item-url {
        span {
          &:after {
            transform: scaleX(1);
            transform-origin: left center;
          }
        }
      }
    }
  }
}
