@use "../../common/scss/core/variables" as *;
@use "../../common/scss/core/mixins" as *;

.contact {

  max-width: $containerWidth;
  padding: 32px;
  margin: 0 auto;

  > h1 {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > p {
    font-size: 1.3em;
    line-height: 1.6em;
    margin: 0;
    text-align: center;
  }

  .contact-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 32px auto;

    .blurb {
      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .contact-image {
    height: 100%;
    border-radius: 10px;
    //@include shadow;
    overflow: hidden;
    margin-bottom: 40px;

    img {
      width: 100%;
    }
  }

}


@media screen and (max-width: $mobile-tablet-max-width) {
  .contact {
    .contact-info {
      flex-direction: column;

      .blurb {
        &:first-child {
          margin-left: auto;
        }

        &:last-child {
          margin-right: auto;
        }
      }
    }
  }
}


@media screen and (max-width: $mobile-max-width) {
  .contact {
    > p {
      font-size: 1em;
    }

    .branding-icon {
      display: none;
    }
  }
}
