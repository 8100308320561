@use 'core/variables' as *;

h1, h2, h3 {
  font-family: $font-family-header;
}

h2 {
  font-size: 2.5em;
}

p {
  line-height: 1.6em;
  font-family: $fontBase;
}

a {
  color: $link-color;
}

img {
  max-width: 100%;
  height: auto;
}

.services-container {
  background: $primary-gradient;

  h2 {
    color: $white;
    text-align: center;
  }
}

.camionet-image {
  max-width: 100%;
  width: auto;
}

.choose-container {
  background: $gray-10;

  h2 {
    text-align: center;
  }

  .button-wrapper {
    max-width: 300px;
    margin: 30px auto 0 auto;
  }
}

.card-row-gray {
  .card {
    -webkit-box-shadow: 0 0.125rem 0.375rem rgb(0 0 0 / 5%), 0 0.5rem 1.2rem rgb(0 0 0 / 10%) !important;
    box-shadow: 0 0.125rem 0.375rem rgb(0 0 0 / 5%), 0 0.5rem 1.2rem rgb(0 0 0 / 10%) !important;
  }
}

.gallery {
  margin: 30px;

  .row > .col {
    padding: 8px;

    a {
      width: 100%;
      height: 100%;
      text-align: center;
      display: inline-block;
    }
  }
}

.long-cta-container {
  padding: 1rem 0 8rem 0;

  .cta-light {
    .text {
      font-size: 1.3em;
      margin-right: 50px;
    }
  }
}

.timeline-wrapper {
  background: $gray-10;

  h2 {
    font-size: 1.5em;
  }
}

.list-checked {
  list-style: none;
  padding-left: 0;

  li {
    &:before {
      font-family: 'Font Awesome 5 Free';
      font-weight: 400;
      content: '\f058';
      font-size: 24px;
      background: $white;
      color: $primary;
      margin-right: 12px;
    }

    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: $mobile-tablet-max-width) {
  h2 {
    font-size: 1.8em;
  }

  .long-cta-container {
    padding: 0;

    .cta-light {
      .text {
        margin-right: 0;
      }
    }
  }

  .list-checked {
    margin: 0;
  }
}
