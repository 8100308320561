@use "src/common/scss/core/variables" as *;
@use "src/common/scss/core/mixins" as *;

.infobar {
  display: flex;
  background: $primary-gradient-reversed;
  color: white;

  .infobar-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 12px 0;
  }

  .infobar {

    &-phone {
      margin-left: 16px;
    }

    &-mail {
      margin-left: 16px;
    }

    &-phone a, &-mail a {
      color: $white;
    }

  }

  .infobar-info {
    margin-left: 8px;
  }
}

@media screen and (min-width: $tablet-min-width) and (max-width: $mobile-tablet-max-width) {
  .infobar .infobar-container {
    justify-content: center;
    width: 100%;
  }
}

@media screen and (max-width: $mobile-max-width) {
  .infobar {
    display: none;
  }
}
