@use "src/common/scss/core/variables" as *;
@use "src/common/scss/core/mixins" as *;

.blurb {
  @include shadow;

  display: flex;
  flex-direction: row;
  border-radius: 10px;
  padding: 32px;
  margin: 16px;
  min-width: 230px;
  max-width: 768px;
  width: 100%;
  box-sizing: border-box;
  transition: 0.2s ease-in;

  &:hover {
    transform: translateY(-5px);
    cursor: pointer;
    box-shadow: 0 0.2rem 1rem rgb(0 0 0 / 5%), 0 0.5rem 1.2rem rgb(0 0 0 / 10%) !important;
  }

  .blurb-icon {
    color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($primary, 0.1);
    border-radius: 50%;
    margin-right: 24px;
    font-size: 24px;
    line-height: 24px;
    width: 40px;
    height: 40px;
    padding: 8px;
  }

  .blurb-body {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .blurb-title {
      font-weight: bold;
    }
  }
}

@media screen and (max-width: $mobile-tablet-max-width) {
  .blurb {
    margin: 20px auto;
  }
}
