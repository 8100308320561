@use "core/_normalize" as *;
@use "core/_fonts" as *;
@use "core/_variables" as *;
@use "core/_mixins" as *;
@use "core/_common" as *;
@use "core/_grid" as *;
@use "common" as *;

// lightbox effect: Uikit Lightbox
@use "../../../node_modules/simplelightbox/dist/simple-lightbox.min.css" as *;
@use "gallery" as *;

// TODO change to parameter footer or header 1,2,3, ...
// Components
//@use "../../components/footers/footer-1/footer.scss" as *;
@use "../../components/footers/footer-1/footer.scss" as *;
@use "../../components/headers/header-1/header.scss" as *;
//@use "../../components/headers/header-centered/header.scss" as *;
@use "../../components/navigation/navigation-1/navigation.scss" as *;
//@use "../../components/navigation/navigation-2/navigation.scss" as *;
//@use "../../components/navigation/navigation-3/navigation.scss" as *;
@use "../../components/cookie-consent/cookie-consent.scss" as *;
@use "../../components/buttons/button-primary/button-primary.scss" as *;
@use "../../components/card/card.scss" as *;
@use "../../components/blurb/blurb.scss" as *;
@use "../../components/timeline/timeline" as *;

@use "../../components/infobars/infobar-1/infobar.scss" as *;

// Widgets
@use "../../components/footers/widgets/services/services.scss" as *;
@use "../../components/footers/widgets/social/social.scss" as *;

// Pages
@use "src/pages/scss/contact" as *;

// sections
@use "../../sections/full-width-2-column/full-width-2-column.scss";
@use "../../sections/jumbotron-gaussian/jumbotron-gaussian.scss";
@use "../../sections/jumbotron-parallax/jumbotron-parallax.scss";
@use "../../sections/cta/cta.scss";
@use "../../sections/quote/quote.scss";
@use "../../sections/photo-gallery/photo-gallery";
@use "../../sections/full-screen-header/full-screen-header.scss";


.shadow {
  box-shadow: 0 0 1rem rgba(0,0,0,0.1);
}
