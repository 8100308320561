@use "../header-main";

@use "src/common/scss/core/variables" as *;
@use "src/common/scss/core/mixins" as *;

.header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  border-bottom: 1px solid transparent;
  background: transparent;
  z-index: 10;
  transition: background-color .2s ease, border-color .2s ease;
  padding: 10px;
  background: $header-background-color;
  color: $header-text-color;

  .isScrolled & {
    background-color: $white;
  }

  &.header-shadow {
    @include section-shadow;
  }

  .site-branding {
    display: flex;
    align-items: center;

    .site-branding-url {
      display: flex;
      align-items: flex-start;

      .site-logo {
        max-width: 300px;
        max-height: 50px;
        min-height: 50px;
        width: 100%;
      }
    }
  }

  .header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.hamburger {
  display: none;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: $header-text-color;
}

@media screen and (max-width: $mobile-tablet-max-width) {
  .primary-menu {
    position: fixed;
    left: -100%;
    top: 147px;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    text-align: center;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    padding-left: 0;
    margin: 0;

    &.active {
      left: 0;
    }
  }

  .menu-item {
    margin: 2.5rem 0;
  }

  .hamburger {
    display: block;
    cursor: pointer;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

}

@media screen and (min-width: $tablet-min-width) and (max-width: $mobile-tablet-max-width) {
  .primary-menu {
    top: 71px;
  }

  .header {
    .isScrolled & {
      top: 0;

      .primary-menu {
        top: 71px;
      }
    }
  }
}

@media screen and (min-width: $desktop-min-width) {
  .header {
    .isScrolled & {
      top: 0;
    }
  }
}

@media screen and (max-width: $mobile-max-width) {
  .header {
    top: 0;

    .site-branding {
      .site-branding-url {
        .site-logo {
          max-width: 200px;
          max-height: 50px;
        }
      }
    }
  }

  .primary-menu {
    top: 71px;
  }
}
