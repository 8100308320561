.social-wrapper {

  .social-container {
    list-style: none;
    padding: 0;
    text-align: center;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    .social {
      margin: 0;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 40px;
      position: relative;

      a {
        width: 40px;
        height: 40px;
      }

      &:hover {
        .social-icon {
          &:before {
            opacity: 0.2;
            top: 40px;
          }

          &:after {
            top: 0;
            opacity: 1;
          }
        }
      }
    }
  }
}

.social-icon {
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  display: inline-block;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 20px;

  &:before, &:after {
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease-out 0s;
  }

  &:after {
    top: -40px;
    opacity: 0.2;
  }
}

.fa-instagram {
  &:after {
    content: "\f16d";

  }
}

.fa-facebook-f {
  &:after {
    content: "\f39e";
  }
}
