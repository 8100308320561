@use "src/common/scss/core/variables" as *;

.photo-gallery-wrapper {
  background: $black;
  color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;

  &.light {
    background: $white;
    color: $black;
  }

  &.preview {
    background: $photo-gallery-preview-background;
    color: $photo-gallery-preview-color;

    hr {
      background: $photo-gallery-preview-color;
    }
  }

  .gallery-title {
    margin-bottom: 32px;

    h1 {
      text-align: center;
      font-size: 3em;
      margin-bottom: 0.2em;
    }

    hr {
      width: 70px;
      height: 4px;
      border: 0;
      background: $white;
    }
  }

  .gallery {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 16px 16px;

    .col {
      flex: 1 1 0px;
      display: flex;
      flex-direction: column;

      a {
        line-height: 0;
        margin: 8px;
      }

      img {
        width: 100%;
        max-width: 600px;
        height: auto;
        border: 4px solid;
        border-image-slice: 1;
        border-image-source: $primary-gradient;
        padding: 8px;
      }

      &:first-child {
        align-items: end;
        margin: 2px 8px 8px 16px;

      }

      &:last-child {
        align-items: start;
        margin: 2px 16px 8px 8px;

      }
    }
  }

  .more-photos {
    margin-bottom: 64px;
  }
}

@media screen and (max-width: $mobile-tablet-max-width) {
  .photo-gallery-wrapper {
    .gallery-title {
      h1 {
        font-size: 2em;
        margin-bottom: 0.2em;
      }
    }
  }
}

@media screen and (min-width: $tablet-min-width) and(max-width: $mobile-tablet-max-width) {
  .photo-gallery-wrapper {
    .gallery-title {
      h1 {
        font-size: 3em;
      }
    }
  }
}

@media screen and (max-width: $mobile-max-width) {
  .photo-gallery-wrapper {
    .gallery {
      flex-direction: column;

      .col {
        a {
          display: flex;
          justify-content: center;
        }

        img {
          width: 90%;
          text-align: center;
        }

        &:first-child {
          margin: 0;
          align-items: center;
        }

        &:last-child {
          margin: 0;
          align-items: center;
        }
      }
    }
  }
}
