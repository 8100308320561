@use "src/common/scss/core/variables" as *;
@use "src/common/scss/core/mixins" as *;

.site-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;

  &.mobile {
    display: none;
  }

  &.centered .primary-menu .menu-item {
    margin-left: 0;
  }

  .mobile-menu {
    background: $white;
    z-index: 100;
    @include section-shadow;
  }

  .primary-menu, .mobile-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style-type: none;

    .menu-item {
      margin-left: 20px;
      font-size: $font-size-menu-item;
      font-weight: 400;
      padding: 0 8px;
      align-self: stretch;

      .menu-item-url {
        padding: 8px;
        text-decoration: none;
        color: $header-text-color;
        text-transform: uppercase;

        span {
          position: relative;

          &:after {
            content: " ";
            position: absolute;
            background: $primary;
            top: 100%;
            margin-top: 4px;
            left: 0%;
            width: 100%;
            height: 3px;
            transform: scaleX(0);
            transition: -webkit-transform 0.2s;
            transition: transform 0.2s;
            transform-origin: right center;
            box-sizing: border-box;
            display: block;
          }
        }
      }

      &:hover {
        .menu-item-url {
          span {
            &:after {
              transform: scaleX(1);
              transform-origin: left center;
            }
          }
        }
      }
    }
  }
}

.centered.home .menu-item-url {
  color: white !important;
}

@media screen and (min-width: $desktop-min-width) {
  .site-navigation {
    .mobile-menu {
      display: none;
    }
  }
}

@media screen and (max-width: $mobile-tablet-max-width) {
  .site-navigation-cta {
    display: none !important;
  }

  .site-navigation .primary-menu .menu-item {
    padding: 0;
    margin: 1rem 0;
  }

  .centered.home .menu-item-url {
    color: $black !important;
  }

  .site-navigation {
    &.mobile {
      display: flex;
      height: 0;
      padding: 0;
    }
  }
}
